.children {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  width: 100%;

  /* align-self: flex-start; */
  margin-top: 9rem;
}
