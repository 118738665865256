.singleBidDetailCard {
  width: 94%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 90px 1fr;

  background-color: #353535;
  padding: 0.4rem;
  border-radius: 5px;

  box-shadow: inset 0 0 0 1px #666666;
}

.animationFlash {
  animation: shadow-flash 3s ease-in-out infinite;
}

@keyframes shadow-flash {
  0% {
    box-shadow: inset 0 0 0 1px #666666;
  }
  50% {
    box-shadow: inset 0 0 0 1px #ff0000;
  }
  100% {
    box-shadow: inset 0 0 0 1px #666666;
  }
}

.bidPlacedWaiting {
  box-shadow: inset 0 0 0 1px #fc8018;
}

.bidAccepted {
  box-shadow: inset 0 0 0 1px #69a43c;
}
