.overduePopup {
  position: absolute;
  z-index: 50;
  height: 90vh;
  width: min(100vw, 425px);
}

.modalBackdrop {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(0.5px);

  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #fc7f1860; */
}

.visibleModal {
  background-color: #393939;
  width: max(300px, 75%);
  padding: 1rem;
  border-radius: 5px;

  /* border: 2px solid rgba(255, 166, 0, 0.093); */

  display: flex;
  flex-direction: column;
  /* gap: 2rem; */
}

.amountDisplay {
  /* margin-top: 1rem; */
  font-size: 2.8rem;
  font-weight: 700;
  text-align: center;
}

.amountRed {
  color: rgb(255, 48, 48);
}

.addMoneyText {
  font-size: 1.8rem;
  text-align: center;
}
