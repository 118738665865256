.homescreenGoogleMap {
  width: 100%;
  /* aspect-ratio: 1/1; */

  /* height: 425px; */
  /* 13 rem = topbar + on Duty + Hllo Driver, 100 px = advertisement banner */
  height: calc(100vh - 13rem - 120px);
  background-color: #383838;

  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 auto;

  position: relative;
  margin-top: 1rem;
}

.loadingState {
  display: flex;
  align-items: center;
  justify-content: center;
}
