.liveBidHeadingWithSort {
  width: 94%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.headingLiveBids {
  font-size: 2.6rem;
  font-weight: 600;
}

.headingLiveBidsSearching {
  font-size: 1.6rem;
  font-weight: 600;
}
