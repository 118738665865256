.errorInNumber {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.4rem;
  text-align: center;
  align-self: flex-start;
  margin-left: 1.2rem;
  color: #ff0000;
  width: 100%;
}

.fontLarge {
  font-size: 1.6rem;
  /* font-weight: ; */
}

.extraMessage {
  color: rgb(255, 251, 0);
  font-size: 1.4rem;
}
