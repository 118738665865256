.rmhScreenWrapper {
  height: 100vh;
  width: min(425px, 100vw);
  background-color: var(--main-bg-color-black);

  position: relative;
}

.appbar {
  width: 100%;
  top: 0;
  /* No absolute position required for our use case */
  /* position: absolute; */
  z-index: 1;
  background-color: #383838;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  margin-bottom: 0.2rem;
}

.hamburgerMenu {
  position: absolute;
  /* top: 0; */
  /* left: 0; */
  width: 70%;
  height: 100vh;
  z-index: 10;
  background-color: #1f1f1f;

  padding: 1rem;

  display: flex;
  flex-direction: column;
}

.hamburgerSideClosing {
  position: absolute;
  /* top: 0; */
  right: 0;
  width: 30%;
  height: 100vh;
  z-index: 10;
  background-color: transparent;
}

.content {
  /* position: absolute; */
  flex: 1;
  height: 92%;
  overflow-y: auto;
  background-color: var(--main-bg-color-black);
  padding-bottom: 2rem;
}

.bottomModal {
  position: fixed;
  bottom: 0;
  z-index: 5;
  height: min-content;
  background-color: #383838;
  width: 100vw;

  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

/* ********************* */
/* Appbar */

.hamburgerIcon {
  height: 24px;
  width: 24px;

  /* margin-left: 1rem; */
  color: #fff;

  filter: invert();
}

.logoWithTextRow {
  width: 55%;
  height: auto;
}

.userGeneric {
  height: 24px;
  width: 24px;

  /* margin-right: 1rem; */
  filter: invert();
}

/* ********************* */
/* Hamburger menu */

.crossButton {
  background: transparent;
  color: #fff;
  outline: none;
  border: none;

  font-size: 2.4rem;
  font-weight: 700;
  margin-left: 1rem;
  margin-top: 1rem;

  align-self: flex-start;
}

.crossButton:active {
  outline: none;
  background: transparent;
}

.hamburgerList {
  list-style: none;
  font-size: 2rem;
  font-weight: 400;
}

.liClass {
  margin-top: 3rem;
  margin-left: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.liClass img {
  height: 24px;
  width: 24px;

  filter: invert();
}

.termsOfService {
  font-size: 1.4rem;

  font-weight: 400;

  color: #387bf5;

  margin: auto 0 3.6rem 1.5rem;
}
