.floatingAction {
  z-index: 5;
  position: absolute;
  bottom: 4rem;

  font-size: 1.6rem;
}

.left {
  left: 3rem;
}

.right {
  right: 3rem;
}

.floatingActionButton {
  /* Default */
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  /* Custom */
  background-color: #419c00;
  padding: 2rem;
  outline: none;
  border-radius: 30px;
  min-width: 130px;
  color: #fff;
  font-weight: bold;
  -webkit-tap-highlight-color: transparent;
  /* transition: background-color 0.3s ease-in; */
  user-select: none;
}

.floatingActionButton:focus {
  outline: none;
}

.floatingActionButton:active {
  background-color: #347c00;
}
