.viewRidesWithMyBids {
  display: flex;
  flex-direction: column;

  gap: 1.2rem;
  margin-top: 2rem;
}

.bidContainer {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.floatingAction {
  z-index: 5;
  position: absolute;
  bottom: 4rem;

  font-size: 1.6rem;
}

.left {
  left: 3rem;
}

.right {
  right: 3rem;
}

.floatingActionButton {
  /* Default */
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  /* Custom */
  /* background-color: #419c00; */
  padding: 2rem;
  outline: none;
  border-radius: 30px;
  min-width: 130px;
  color: #fff;
  font-weight: bold;
  -webkit-tap-highlight-color: transparent;
  /* transition: background-color 0.3s ease-in; */
  user-select: none;
}

.floatingActionButton:focus {
  outline: none;
}

.floatingActionButton:active {
  background-color: #347c00;
}

.greenColorBg {
  background-color: #419c00;
  border-radius: 30px;
}
.flashing {
  animation: flashColor 0.5s infinite;
  transition: background-color 0.5s ease-in-out;
  border-radius: 30px;
}

@keyframes flashColor {
  0% {
    background-color: #347c00;
  }
  50% {
    background-color: rgb(167, 22, 22);
  }
  100% {
    background-color: #347c00;
  }
}

.InputlogsBottomModal {
  position: absolute;
  z-index: 10;

  height: 90vh;
  width: min(100vw, 425px);
}

.InputModalBackdrop {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(1px);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background-color: #fc7f1860; */
}
.InputVisibleModal {
  background-color: #393939;
  width: max(300px, 75%);
  padding: 1rem;
  border-radius: 5px;

  border: 2px solid rgba(255, 166, 0, 0.093);

  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.modalMainHeading {
  margin-top: 1rem;
  font-size: 1.8rem;
  font-weight: 400;
  text-align: center;
}

.labelWithInput {
  width: 100%;
}

.inputLabel {
  font-size: 1.4rem;

  font-weight: 700;
  text-align: left;
  width: 94%;
  margin: 0 auto;
  margin-bottom: 0.4rem;
}

.fileUploadName {
  display: flex;
  align-items: center;
  background-color: #e9e9e9;
  border-radius: 3px;
  width: 94%;
  margin: 0 auto;

  height: 49px;
  color: #848484;
  justify-content: space-between;
}

.labelName {
  margin-left: 1rem;
  font-size: 1.4rem;
}

.camera {
  height: 22px;
  width: 25px;
  margin-right: 1rem;
}

.button {
  /* equivalent to leaving round about 14px space */
  width: 94%;
  /* height: 49px; */
  background-color: #fc8018;
  margin: 0 auto;
  border-radius: 3px;
  padding: 1.5rem 0;

  font-size: 1.4rem;
  font-weight: 700;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;
}
