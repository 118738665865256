.suggestionAcceptActiveRideView {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.longHrLine {
  border: none;
  height: 1px;
  width: 94%;
  background-color: #626161;
  align-self: center;
}

.suggestedDataAcceptLine {
  display: flex;
  font-size: 2.4rem;
  font-weight: bold;
  margin-top: 1rem;
  /* justify-content: space-between; */
  margin-right: 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.suggestedAmountsExtras {
  display: flex;
  gap: 0.2rem;
}

.buttonGeneral {
  padding: 0.8rem 1rem 0.4rem 1rem;
  font-weight: inherit;
}

.incrementDecrementAmountButton {
  background-color: transparent;
  outline: none;
  border: none;

  box-shadow: 0 0 0 1px inset orange;
  border-radius: 5px;
  color: orange;
}

.acceptPlaceBidButton {
  margin-left: 1rem;
  background-color: transparent;
  border: none;

  box-shadow: 0 0 0 1px inset orange;
  background-color: orange;
  border-radius: 10px;
}

.viewDetailsButton {
  background-color: transparent;
  border: none;
  padding: 1rem 1rem 0.8rem 1rem;
  width: 100%;
  margin: 0 auto;
  font-size: 1.6rem;
  font-weight: bold;
  box-shadow: 0 0 0 1px inset orange;
  border-radius: 5px;
  color: orange;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.disabledButton {
  color: #797979;
  box-shadow: 0 0 0 1px inset #797979;
}
