.otherDriverBidCard {
  display: flex;
  /* gap: 0.4em; */

  font-size: 1.6rem;

  padding: 0.4rem 1rem;
  border-radius: 30px;
  box-shadow: 0 0 0 1px #fc8018;
}

.ratingWithStar {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
}
.orangeStar {
  height: 16px;
  width: 16px;
}

.orangeStarAverage {
  filter: brightness(0);
}

.averagePrice {
  background-color: #fc7f18f4;
}

.priceParaWidth {
  width: 60px;
}
.ratingParaWidth {
  width: 55px;
}
