.currentStateTimePriceBids {
  display: flex;
  flex-direction: column;
  width: 90px;
}

.loadingStatusTime {
  min-height: 70px;
  background-color: #dddddd;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  border-radius: 3px 3px 0 0;
}

.priceBids {
  min-height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;

  border-radius: 0 0 3px 3px;

  background-color: #000;
}

.backgroundColorAnimation {
  animation: shadow-flash 3s ease-in-out infinite;
}

@keyframes shadow-flash {
  0% {
    background-color: #000;
  }
  50% {
    background-color: #ff0000;
  }
  100% {
    background-color: #000;
  }
}

.hourGlass {
  height: 17px;
  /* color to be determined */
  /* filter: invert() saturate() sepia() hue-rotate(); */
}

.flatGreenCircleBlackTick {
  height: 20px;
  width: 20px;
}

.time {
  font-size: 1.4rem;
  font-weight: 700;
  color: #000;
}

.priceBids span:nth-child(1) {
  font-size: 1.4rem;
  font-weight: 700;
}

.priceBids span:nth-child(2) {
  font-size: 1rem;
  font-weight: 400;
}

/* TODO */
/* Add states for waiting and accepted bids */

/* #FC8018 */

.bidPlacedWaiting {
  background-color: #fc8018;
}

.bidAccepted {
  background-color: #69a43c;
}
