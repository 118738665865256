.nameEmailPhoneLine {
  display: flex;
  /* align-items: center; */
  /* justify-content: space-between; */

  font-size: 1.6rem;

  /* background-color: rgba(26, 108, 179, 0.53); */
}

.commonVerticleGap {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.itemLeft {
  /* background-color: rgba(23, 154, 149, 0.553); */
  text-align: right;
  font-weight: bold;
}

.itemCenter {
  /* background-color: rgba(250, 3, 3, 0.475); */
}

.itemRight {
  /* background-color: rgba(170, 207, 21, 0.427); */
}

.dot {
  margin: 0 1.4rem 0 0.2rem;
}

.idLabel {
  margin-right: -1rem;
}

.boldName {
  font-weight: bold;
  margin-left: -1rem;
}
