.children {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.9rem;
  width: 100%;

  margin-top: -12rem;
}

.translate {
  transform: translate(-50%, 0);
  margin-bottom: 1rem;
}
