.loginSignSwitchLine {
  font-size: 1.6rem;
  font-weight: 600;
}

.loginSignSwitchLineFunctionButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-decoration: underline;
  margin-left: 0.4rem;
  font-weight: 900;
  font-size: 2rem;
  color: #fc8018;
}
