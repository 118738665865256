.singleBidDetailCardPlaceBid {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;

  background-color: #353434;
  padding: 0rem 0.4rem;
  padding-top: 1.4rem;
  border-radius: 8px;

  /* box-shadow: inset -5px 5px 5px -5px #c3bfbf; */
  -webkit-box-shadow: inset 1px 1px 10px 5px #565656;
  box-shadow: inset 1px 1px 10px 5px #565656;

  padding-bottom: 5px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  /*  */
}

.activateTopBottomPadding {
  padding-bottom: 1.4rem;
}

.animationFlash {
  /* animation: shadow-flash 3s ease-in-out infinite; */
}

@keyframes shadow-flash {
  0% {
    box-shadow: inset 0 0 0 1px #666666;
  }
  50% {
    box-shadow: inset 0 0 0 1px #ff0000;
  }
  100% {
    box-shadow: inset 0 0 0 1px #666666;
  }
}

.bidPlacedWaiting {
  box-shadow: inset 0 0 0 1px #fc8018;
}

.bidAccepted {
  box-shadow: inset 0 0 0 1px #69a43c;
}
