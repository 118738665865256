.campaignDialogBox {
  position: absolute;
  z-index: 60;
  height: 90vh;
  width: min(100vw, 425px);
}

.modalBackdrop {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(1px);
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #fc7f1860; */
}
.visibleModal {
  background-color: #353535;
  background-image: linear-gradient(135deg, #252525 0%, rgb(52, 52, 52) 100%);
  width: "100%";
  padding: 0.6rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}


.buttonRow {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;
  outline: none;
  font-size: 1.6rem;
  padding: 0.6rem 1rem;
  border-radius: 5px;
  box-shadow: inset 0 0 0 1px #ff7300;
  min-width: 96px;
}
