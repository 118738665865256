.walletRidePoints {
  display: flex;
  flex-direction: column;
  width: 92%;

  gap: 1rem;
  /* background-color: rgba(24, 116, 197, 0.463); */
}

.topRow {
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 1rem;
  /* background-color: rgba(24, 169, 19, 0.527); */
}

.bottomRow {
  height: 105px;
  background-color: #fc8018;
}

.topBlocks {
  padding: 0 1rem;
  background-color: #37474f;
  /* width: 50%; */
  height: 105px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.rides {
  /* width: 50%; */
  background-color: rgba(70, 18, 214, 0.527);
}

.icons {
  height: 20px;
  /* width: 20px; */
}

.insideBlockTopRow {
  /* background-color: rgba(0, 255, 255, 0.452); */
  display: flex;
  gap: 1rem;
  align-items: center;

  font-size: 1.2rem;
  font-weight: 700;
}

.insideBlockTopRow p {
  margin-top: 0.3rem;
}

.amountDisplay {
  margin-top: 1rem;
  font-size: 3.6rem;
  font-weight: 700;
}

.bgColorOverride {
  background-color: #fc8018;
}
