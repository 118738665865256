.rideDetailsEndRide {
  display: flex;
  flex-direction: column;

  align-items: center;
  gap: 1rem;
}

.message {
  font-size: 1.6rem;
  font-weight: 700;
  margin: 1rem;
  text-align: center;
}
