.actualPastRideCard {
  display: flex;
  padding: 1rem 0;
  background-color: #212121;

  overflow-y: auto;
}

.left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cab {
  /* height: 25px; */
  width: 49px;
  margin: 1rem;

  object-fit: contain;
  /* filter: invert(); */
}

.middle {
  flex-grow: 1;
  display: flex;

  flex-direction: column;

  gap: 1rem;
  margin-left: 1rem;

  color: #b6b6b6;
}

.upper {
  font-size: 1.4rem;
}

.driverNameNumberLine {
  color: white;
  display: flex;
  gap: 2rem;
  font-size: 1.6rem;
}

.anchorReset {
  text-decoration: none;
  color: white;
}

.lower {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.line {
  display: flex;
  gap: 0.6rem;
}

.dotGreen {
  /* display: inline-block; */
  border-radius: 5px;
  height: 10px;
  aspect-ratio: 1/1;
  /* width: 10px; */
  /* margin-right: 0.6rem; */
  padding: 1px 1px;

  background-color: #6ea002;
  color: white;
}
.dotRed {
  /* display: inline-block; */
  border-radius: 5px;
  border-radius: 10px;
  height: 10px;
  aspect-ratio: 1/1;
  /* width: 10px; */
  color: white;
  padding: 1px 1px;

  background-color: #f05151;
}

.bottomTwo {
  display: flex;
  align-items: center;

  font-size: 1.2rem;

  /*  */
  /* flex-wrap: wrap; */
  gap: 0.6rem;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0 1rem;
}

.right img {
  height: 40px;
  width: 40px;
  /* filter: invert(); */
}

.cancelled {
  color: #ff0000;
  font-size: 1.4rem;
  font-weight: 700;
}

.rideAmount {
  font-size: 1.4rem;
  font-weight: 700;
}

.commission {
  color: #ff0000;
  font-size: 1.4rem;
  font-weight: 700;
  margin-top: 0.5rem;
}

.upper .line:nth-child(2) span:nth-child(2) {
  text-transform: uppercase;
  color: rgb(255, 115, 0);
  font-weight: bold;
  letter-spacing: 2px;
}
