.bidSecurity {
  background-color: #37474f;
  width: 92%;
  /* height: 55px; */
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  gap: 1rem;
}

.bideSecurityZeroCenter {
  align-items: center;
}

.topRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.topRowLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.addAmountButtonWidth {
  width: 30%;
}

.bidSecurityText {
  font-size: 1.8rem;
  font-weight: 700;
  /* margin-top: 0.8rem; */
}

.amountDisplay {
  margin-top: 1rem;
  font-size: 3rem;
  font-weight: 700;
}

.amountRed {
  color: red;
}

.addMoneyText {
  font-size: 1.4rem;
  text-align: center;
}
