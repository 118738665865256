/* .scrollable {
  height: 90vh;
  overflow-y: auto;
  
  width: 100%;

  scrollbar-width: thin;
  scrollbar-color: #555555 #00000000;
}


.scrollable::-webkit-scrollbar {
  width: 4px; 
}

.scrollable::-webkit-scrollbar-track {
  background-color: #00000000; 
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #555555; 
  border-radius: 5px;
} */

.driverEditProfilePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  /* margin-top: 1rem; */
}
