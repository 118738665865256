.bidAcceptedMeetAtPickupPoint {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 1rem;
}

.box {
  background-color: transparent;
  display: flex;
  gap: 1rem;
}

.inputBox {
  background-color: #d9d9d9;
  border: none;
  outline: none;
  border-radius: 7px;
  height: 40px;
  width: 40px !important;
  margin: 1rem;
  font-size: 1.6rem;
  font-weight: 700;
  color: #000;

  /* padding: 2rem; */
  transition: background-color 0.3s ease;
}

input:focus {
  background-color: #fffcfc;
}

.enterOtpMessage {
  font-size: 1.6rem;
  text-align: center;
  width: 94%;
}

.flashingScheduleTime {
  animation: flash 2s infinite;
}
@keyframes flash {
  0% {
    color: rgb(255, 251, 0);
  }
  50% {
    color: rgb(37, 206, 37);
  }
  100% {
    color: rgb(255, 251, 0);
  }
}
