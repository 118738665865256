.topGreenCheckStatus {
  width: 94%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.greenCircleBlackTick {
  height: 20px;
  width: 20px;
}

.displayText {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: -0.4rem;
}
