.placeBidButton {
  display: flex;

  /* align-items: center; */
  /* justify-content: space-between; */
  width: 94%;
  background-color: #dcdcdc;

  border-radius: 3px;
}
.infoBar {
  display: flex;
  width: 150%;

  flex-direction: column;

  color: #000;
  align-items: flex-start;
  justify-content: center;

  padding-left: 1rem;
}

.inputStyle {
  padding: 1rem 0;

  background-color: #dcdcdc;

  border: none;

  outline: none;

  font-size: 1.8rem;
  font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
.inputStyle::-webkit-outer-spin-button,
.inputStyle::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.inputStyle[type="number"] {
  -moz-appearance: textfield;
}
.inputStyle:focus {
  background-color: #dcdcdc;
}
