.spinner {
  display: inline-block;
  /* width: 40px;
  height: 40px; */
  margin: 0 auto;
}
.spinner:after {
  content: " ";
  display: block;
  width: 16px;
  height: 16px;
  margin: 8px;
  border-radius: 50%;
  border: 3px solid #4b4b4b;
  border-color: #4b4b4b transparent #4b4b4b transparent;
  animation: spinner 1.2s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
