.button {
  /* equivalent to leaving round about 14px space */
  width: 94%;
  /* height: 49px; */
  background-color: #fc8018;
  margin: 0 auto;
  border-radius: 3px;
  padding: 1.5rem 0;

  font-size: 1.4rem;
  font-weight: 700;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.imageInsideButton {
  height: 6rem;
}

.customFont {
  font-size: 2.4rem;
  padding: 0.4rem 0;
  /* width: ; */
  background-color: gainsboro;
  color: black;
  -webkit-box-shadow: inset 1px 1px 10px 1px #b6b6b6;
  box-shadow: inset 1px 1px 10px 1px #b6b6b6;

  padding-bottom: 5px;
  /* border-top-left-radius: 8px;
  border-top-right-radius: 8px; */

  /* cursor: pointer; */
}

.leftImageCustomStyle {
  align-self: flex-start;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
