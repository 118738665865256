.fairSuggestionPopUp {
  position: absolute;
  z-index: 15;

  height: 90vh;
  /* bottom: 0; */
  /* height: 90dvh; */
  /* height: 90dvh; */
  width: min(100vw, 425px);
}

.modalBackdrop {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(1px);

  display: flex;
  align-items: center;
  justify-content: center;

  /* background-color: #fc7f1860; */
}

.visibleModal {
  background-color: #000000;
  width: max(275px, 80%);
  padding: 1rem;
  border-radius: 5px;

  /* border: 2px solid rgba(255, 166, 0, 0.093); */

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modalMainHeading {
  margin-top: 2rem;
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
}
.modalPriceHeading {
  /* margin-top: 1rem; */
  font-size: 1.8rem;
  font-weight: 400;
  text-align: center;
}

.modalSubHeading {
  font-size: 2rem;

  font-weight: bold;
  color: rgb(169, 169, 169);
  text-align: center;
  /* margin-top: 2rem; */
  color: rgb(6, 159, 11);
}

.priceBreakLine {
  font-size: 1.6rem;
  color: rgb(169, 169, 169);
  text-align: center;
  /* margin-bottom: 3rem; */
}

.buttonRow {
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.button {
  /* Resetting default styles */
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  /* line-height: normal; */
  /* text-align: inherit; */
  /* text-decoration: none; */
  cursor: pointer;
  /* overflow: visible; */
  outline: none;

  font-size: 1.6rem;
  padding: 0.6rem 1rem;
  background-color: #fc8018;
  border-radius: 5px;

  min-width: 96px;
  margin-bottom: 1rem;
}

.ulList {
  font-size: 1.4rem;
  color: rgb(169, 169, 169);
  list-style: none;

  padding-left: 1rem;
}
