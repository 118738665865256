.radarVisual {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  margin-top: 4rem;
}
.wrapper {
  position: relative;
  width: 90%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  box-shadow: 0 0 2px #1cffcb, 0 0 15px #1cffcbbd;
}
@keyframes rotate {
  100% {
    transform: rotate(-360deg);
  }
}
.board {
  border-radius: inherit;
}
.board:after {
  content: "";
  border-radius: inherit;
  background-image: conic-gradient(#000 0, #000 35%);
  animation: rotate 3s linear infinite;
  z-index: 1;
}
.board * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
}
.round {
  border-radius: inherit;
  border: 1px solid #fff;
}
.roundSm {
  width: 25%;
  height: 25%;
}
.roundMd {
  width: 50%;
  height: 50%;
}
.roundLg {
  width: 75%;
  height: 75%;
}
.line {
  background-color: #fff;
}
.lineX {
  width: 100%;
  height: 1px;
}
.lineY {
  width: 1px;
  height: 100%;
}
.radar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-image: conic-gradient(#1cffcb 3%, #1cffcbbd 8%, transparent 35%);
  animation: rotate 3s linear infinite;
}
.radar:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-image: conic-gradient(#e3fff9 2%, transparent 4%);
}

.headingLiveBidsSearching {
  font-size: 2.6rem;
  font-weight: 600;
  margin: auto;
}
