.viewRidesWithMyBids {
  display: flex;
  flex-direction: column;

  gap: 1.2rem;
  margin-top: 2rem;
}

.bidContainer {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

/* 
 */

.singleBidDetailCardPlaceBid {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;

  background-color: #353434;
  padding: 0rem 0.4rem;
  padding-top: 1.4rem;
  border-radius: 8px;

  /* box-shadow: inset -5px 5px 5px -5px #c3bfbf; */
  -webkit-box-shadow: inset 1px 1px 10px 5px #565656;
  box-shadow: inset 1px 1px 10px 5px #565656;

  padding-bottom: 5px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  /*  */
}

.activateTopBottomPadding {
  padding-bottom: 1.4rem;
}

.animationFlash {
  /* animation: shadow-flash 3s ease-in-out infinite; */
}

@keyframes shadow-flash {
  0% {
    box-shadow: inset 0 0 0 1px #666666;
  }
  50% {
    box-shadow: inset 0 0 0 1px #ff0000;
  }
  100% {
    box-shadow: inset 0 0 0 1px #666666;
  }
}

.bidPlacedWaiting {
  box-shadow: inset 0 0 0 1px #fc8018;
}

.bidAccepted {
  box-shadow: inset 0 0 0 1px #69a43c;
}

.distancePickupTimeOriginDestination {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;

  justify-content: center;
  gap: 1rem;
}

.upper {
  display: flex;
  flex-direction: column;

  gap: 0.4rem;
}

.totalRideDistance {
  font-size: 1.6rem;
  font-weight: 700;
}

.flashingScheduleTime {
  animation: flash 1s infinite;
  font-size: 1.8rem;
  text-shadow: white;
}
@keyframes flash {
  0% {
    /* color: rgb(18, 110, 14); */
    color: rgb(255, 129, 129);
  }
  50% {
    color: rgb(111, 255, 106);
    /* color: rgb(194, 6, 6); */
  }
  100% {
    color: rgb(255, 129, 129);
    /* color: rgb(224, 121, 121); */
    /* color: rgb(194, 6, 6); */
    
  }
}

.pickupPointAwayTime {
  color: rgb(255, 251, 0);
  letter-spacing: 0.1rem;
  font-size: 1.6rem;
  font-weight: bold;

  display: flex;
  align-items: center;
}

.hrLine {
  border: none;
  height: 2px;
  width: 2rem;
  background-color: #b7b7b7;
  transform: rotate(90deg);
}

.longHrLine {
  border: none;
  height: 1px;
  width: 94%;
  background-color: #626161;
}

.lower {
  display: flex;

  justify-content: space-between;
}

.dot {
  display: inline-block;
  height: 10px;
  aspect-ratio: 1/1;

  border-radius: 50%;

  background-color: #000;

  margin-right: 1rem;
}

.green {
  background-color: #69a43c;
}

.red {
  background-color: #ed7061;
}

.left {
  display: flex;

  flex-direction: column;
  /* align-items: center; */

  justify-content: center;
  gap: 0.4rem;
}

.left p {
  display: flex;

  align-items: center;
  justify-content: left;

  font-size: 1.4rem;
  letter-spacing: 0.1rem;
}

.adedressParaPickup {
  color: rgb(255, 251, 0);
}
.right {
  align-self: flex-end;
  margin-right: 1rem;
}

.arrowBackWhite {
  height: 14px;
  width: 15px;

  transform: rotate(180deg);
}

/* 
 */

.suggestionAcceptActiveRideView {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.longHrLine {
  border: none;
  height: 1px;
  width: 94%;
  background-color: #626161;
  align-self: center;
}

.suggestedDataAcceptLine {
  display: flex;
  font-size: 2.4rem;
  font-weight: bold;
  margin-top: 1rem;
  /* justify-content: space-between; */
  margin-right: 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.suggestedAmountsExtras {
  display: flex;
  gap: 0.2rem;
}

.buttonGeneral {
  padding: 0.8rem 1rem 0.4rem 1rem;
  font-weight: inherit;
}

.incrementDecrementAmountButton {
  background-color: transparent;
  outline: none;
  border: none;

  box-shadow: 0 0 0 1px inset orange;
  border-radius: 5px;
  color: orange;
}

.acceptPlaceBidButton {
  margin-left: 1rem;
  background-color: transparent;
  border: none;

  box-shadow: 0 0 0 1px inset orange;
  background-color: orange;
  border-radius: 10px;
}

.viewDetailsButton {
  background-color: transparent;
  border: none;
  padding: 1rem 1rem 0.8rem 1rem;
  width: 100%;
  margin: 0 auto;
  font-size: 1.6rem;
  font-weight: bold;
  box-shadow: 0 0 0 1px inset orange;
  border-radius: 5px;
  color: orange;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.disabledButton {
  color: #797979;
  box-shadow: 0 0 0 1px inset #797979;
}
