.distancePickupTimeOriginDestination {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;

  justify-content: center;
  gap: 1rem;
}

.upper {
  display: flex;
  flex-direction: column;

  gap: 0.4rem;
}
.distance {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.commission {
  color: rgb(255, 68, 0);
  font-size: 2rem;
  align-self: flex-end;
  margin-right: 0.8rem;
}

.totalRideDistance {
  font-size: 1.6rem;
  font-weight: 700;
}

.pickupPointAwayTime {
  color: rgb(255, 251, 0);
  letter-spacing: 0.1rem;
  font-size: 1.6rem;
  font-weight: bold;

  display: flex;
  align-items: center;
}

.hrLine {
  border: none;
  height: 2px;
  width: 2rem;
  background-color: #b7b7b7;
  transform: rotate(90deg);
}

.longHrLine {
  border: none;
  height: 1px;
  width: 94%;
  background-color: #626161;
}

.lower {
  display: flex;

  justify-content: space-between;
}

.dot {
  display: inline-block;
  height: 10px;
  aspect-ratio: 1/1;

  border-radius: 50%;

  background-color: #000;

  margin-right: 1rem;
}

.green {
  background-color: #69a43c;
}

.red {
  background-color: #ed7061;
}

.left {
  display: flex;

  flex-direction: column;
  /* align-items: center; */

  justify-content: center;
  gap: 0.4rem;
}

.left p {
  display: flex;

  align-items: center;
  justify-content: left;

  font-size: 1.4rem;
  letter-spacing: 0.1rem;
}

.adedressParaPickup {
  color: rgb(255, 251, 0);
}
.right {
  align-self: flex-end;
  margin-right: 1rem;
}

.arrowBackWhite {
  height: 14px;
  width: 15px;

  transform: rotate(180deg);
}
