.yourEarningCommission {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 1.6rem;
}

.tollLine {
  font-size: 1.6rem;
}
